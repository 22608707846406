import { createSlice } from '@reduxjs/toolkit'

const initValue = JSON.parse(localStorage.getItem('user_info')) || {
    id:'',
    avatar: '',
    userName: '',
    email: '',
    authority: [],
    permissions:[]
}


export const initialState = { ...initValue }


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (_, action) => {
            // console.log(action.payload)
            return action.payload
        },
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
