const appConfig = {
    apiPrefix: process.env.REACT_APP_API_BASE,

    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
    gatewaysProviders:
        process.env.REACT_APP_GATEWAYS_PROVIDERS ||
        JSON.stringify(['WORLDPAY', 'E-MERCHANT-PAY']),
    gatewaysAUTHSupportedTypes:
        process.env.REACT_APP_GATEWAYS_PROVIDERS_SUPPORTED_AUTHS_TYPES ||
        JSON.stringify(['BASIC']),
    defaultTestCallbackURL: process.env.REACT_APP_DEFAULT_TEST_CALLBACK_URL,
    defaultProductionCallbackURL:
        process.env.REACT_APP_DEFAULT_PRODUCTION_CALLBACK_URL,
}

export default appConfig
