import ApiService from './ApiService'
// import { signInWithEmailAndPassword } from "firebase/auth";
// import { auth, db } from './Firebase.js';
// import { doc, getDoc, collection, query, where } from "firebase/firestore";

// export async function apiSignIn(data) {

//     return await signInWithEmailAndPassword(auth, data.userName, data.password)
//         .then(async (userCredential) => {
//             // Signed in
//             const user = userCredential.user;
//             const payload = user.accessToken;

//             const userId = user.uid;

//             const docRef = doc(db, "users", userId);
//             const docSnap = await getDoc(docRef);

//             if (docSnap.exists()) {
//                 const userData = docSnap.data();
//                 let c = userData.control || [];
//                 let a = userData.authority || ["ADMIN"];
//                 var userObj = {
//                     avatar: '',
//                     userId:userId,
//                     userName: userData.name || "Unknown User",
//                     email: userData.email || "test@test.com",
//                     wallet_id: userData.wallet_id || "NO_WALLET",
//                     accountType: userData.accountType || "ADMIN",
//                     children: userData.children || [],
//                     authority: a.concat(c) || ["ADMIN"],
//                     control: userData.control || [],
//                 }
//                 var response = {};
//                 response["user"] = userObj;
//                 response["token"] = payload;
//                 response["rememberMe"] = data.rememberMe || false;

//                 return {
//                     code: 200,
//                     data: response ,
//                     message: `Success`
//                 }

//             } else {
//                 throw new Error("User is not exist");
//             }
//         })
//         .catch((error) => {
//             console.log(error);
//             throw new Error("Invalid Credentials");
//         });
// }

// export async function apiSignUp(data) {
//     return null
// }

// export async function apiSignOut(data) {
//     return true;
// }

// export async function apiForgotPassword(data) {
//     return null
// }

// export async function apiResetPassword(data) {
//     return null
// }

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: 'auth/sign-in',
        method: 'post',
        data,
    })
}
export async function apiOtp(data) {
    return ApiService.fetchData({
        url: 'auth/verify-otp',
        method: 'post',
        data,
    })
}

export const resendOtp = async (data) => {
    try {
        const res = await ApiService.fetchData({
            url: 'auth/resend-otp',
            method: 'post',
            data,
        })
        return {
            status: true,
            res: res.data,
        }
    } catch (error) {
        return {
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
        }
    }
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: 'auth/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: 'auth/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: 'forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: 'reset-password',
        method: 'post',
        data,
    })
}
