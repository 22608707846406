import { createSlice } from '@reduxjs/toolkit'

export const initialTableData = {
}

const dataSlice = createSlice({
    name: 'paginationSlice',
    initialState: {
        paginationData: initialTableData,
    },
    reducers: {
        setPaginationData: (state, action) => {
            state.paginationData = action.payload
        },
    },
    extraReducers: {},
})

export const { setPaginationData } = dataSlice.actions

export default dataSlice.reducer
