import { createSlice } from '@reduxjs/toolkit'

export const initialMerchantDataData = {
    selectedMerchant: undefined,
    loading: false
}

const dataSlice = createSlice({
    name: 'merchantDetailSlice',
    initialState: initialMerchantDataData,
    reducers: {
        setSelectedMerchantData: (state, action) => {
            state.selectedMerchant = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
    extraReducers: {},
})
export const selectedMerchant = (state) => state.merchantDetailSlice?.selectedMerchant;
export const loadingMerchant = (state) => state.merchantDetailSlice.loading;
export const { setSelectedMerchantData, setLoading } = dataSlice.actions

export default dataSlice.reducer
