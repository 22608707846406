import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { getDeviceUUID, REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)


    const signIn = async (values) => {
        try {
            values.deviceUuid = getDeviceUUID()
            const resp = await apiSignIn(values)
            if (resp.data) {
                if (resp.data.otp_required) {
                    navigate('/otp', { state: { userCredential: values } })
                    return {
                        status: 'success',
                        message: '',
                    }
                } else {
                    const { token } = resp.data
                    localStorage.setItem(
                        'login_auth_token',
                        JSON.stringify(token)
                    )
                    dispatch(onSignInSuccess(token))
                    if (resp.data.user) {
                        localStorage.setItem(
                            'user_info',
                            JSON.stringify(resp.data.user)
                        )
                        dispatch(setUser(resp.data.user || null))
                    }
                    // const redirectUrl = query.get(REDIRECT_URL_KEY)
                    // // navigate(
                    // //     redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                    // // )
                    navigate(appConfig.authenticatedEntryPath)
                    return {
                        status: 'success',
                        message: '',
                    }
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(setUser(resp.data.user || null))
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        localStorage.removeItem('user_info')
        localStorage.removeItem('login_auth_token')
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
